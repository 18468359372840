
import { defineComponent, ref, watch } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import MyBreadCrumb from '@/components/MyBreadCrumb.vue'
import CommonFooter from '@/components/CommonFooter.vue'
import { useRouter } from 'vue-router'
import { HARDWARES } from '@/utils/database'

export default defineComponent({
  name: 'Hardware',
  components: {
    MyHeader,
    MyBreadCrumb,
    CommonFooter
  },
  emits: ['return-list'],
  setup() {
    /* -------------从路由中获取当前页面参数----------------- */
    // 实例化路由
    const router = useRouter()
    // 定义页面中需要自动更新的数据变量
    let databaseObj: any = {}
    const devieceData = ref({})
    const propertyData = ref([])
    const paramsData = ref([])
    const specialParamsData = ref([])
    const introData = ref({})
    const functionData = ref({})
    const otherData = ref({})
    const currentProductIndex = ref(0)
    const tabsPane = ref([])
    const currentTabsIndex = ref(0)
    // 数据初始化
    function initData() {
      const { type } = router.currentRoute.value.query
      databaseObj = HARDWARES.find(item => item.name === type) || {}
      devieceData.value = databaseObj.devieceData || {}
      propertyData.value = databaseObj.propertyData || []
      paramsData.value = databaseObj.paramsData || []
      specialParamsData.value = databaseObj.specialParamsData || []
      introData.value = databaseObj.introData || {}
      functionData.value = databaseObj.functionData || {}
      otherData.value = databaseObj.otherData || {}
      tabsPane.value = databaseObj.tabsPane || []
      currentProductIndex.value = 0
    }
    // 路由变化时，刷新数据
    watch(router.currentRoute, () => {
      initData()
    })
    /* -------------从路由中获取当前页面参数End----------------- */

    // 如果是第一次访问页面，初始化数据
    if (JSON.stringify(databaseObj) === '{}') {
      initData()
    }

    return {
      devieceData, // 设备简介模块数据
      propertyData, // 属性配置模块数据
      paramsData, // 参数模块数据
      specialParamsData, // 参数模块， 多列
      introData, // 产品介绍模块数据
      functionData, // 产品功能模块数据
      otherData, // 其他介绍的数据
      currentProductIndex, // 产品介绍当前索引
      tabsPane, // tabs形式的产品介绍
      currentTabsIndex // 当前tabs索引
    }
  }
})
