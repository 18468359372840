import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1842ca2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hardware" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "deviece" }
const _hoisted_4 = { class: "image" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "title-zh" }
const _hoisted_8 = { class: "title-en" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = {
  key: 0,
  class: "property"
}
const _hoisted_11 = { class: "property-list" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = {
  key: 1,
  class: "intro"
}
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "content" }
const _hoisted_17 = {
  key: 2,
  class: "function"
}
const _hoisted_18 = { class: "label" }
const _hoisted_19 = { class: "content" }
const _hoisted_20 = {
  key: 3,
  class: "params"
}
const _hoisted_21 = { class: "params-list" }
const _hoisted_22 = { class: "title" }
const _hoisted_23 = { class: "table-box" }
const _hoisted_24 = {
  key: 0,
  class: "value"
}
const _hoisted_25 = {
  key: 1,
  class: "value"
}
const _hoisted_26 = {
  key: 4,
  class: "special-params"
}
const _hoisted_27 = { class: "params-list" }
const _hoisted_28 = { class: "title" }
const _hoisted_29 = { class: "table-box" }
const _hoisted_30 = {
  key: 5,
  class: "intro"
}
const _hoisted_31 = { class: "desc-list" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "content" }
const _hoisted_34 = {
  key: 6,
  class: "tabsPane"
}
const _hoisted_35 = { class: "title-list" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "content-list" }
const _hoisted_38 = { class: "sub-title" }
const _hoisted_39 = { class: "sub-list" }
const _hoisted_40 = { class: "sub-list" }
const _hoisted_41 = { class: "sub-list" }
const _hoisted_42 = { class: "sub-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyHeader = _resolveComponent("MyHeader")!
  const _component_MyBreadCrumb = _resolveComponent("MyBreadCrumb")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MyHeader, { currentMenuIndex: 2 }),
    _createVNode(_component_MyBreadCrumb, {
      listName: "硬件产品",
      detailName: _ctx.devieceData.zhName,
      onReturnList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('return-list')))
    }, null, 8, ["detailName"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.devieceData.img,
            alt: ""
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.devieceData.zhName), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.devieceData.enName), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.devieceData.content), 1)
        ])
      ]),
      (_ctx.propertyData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyData, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "property-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(item.label), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(item.value), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (JSON.stringify(_ctx.introData) !== '{}')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.introData.label), 1),
            _createElementVNode("div", _hoisted_16, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.introData.textObj, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text",
                  key: index
                }, _toDisplayString(item), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (JSON.stringify(_ctx.functionData) !== '{}')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.functionData.label), 1),
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.functionData.textObj, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text",
                  key: index
                }, _toDisplayString(item), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.paramsData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paramsData, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "params-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_23, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrenList, (item2, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(Math.floor(index2 / 4) % 2 === 0 ?  'sub-item' : 'sub-item bg-gray'),
                        style: _normalizeStyle(`width: ${ item2.col ? item2.col * 25 : 25 }%; background-color: ${ item2.backgroundColor ? item2.backgroundColor : 'auto' }`),
                        key: index2
                      }, [
                        _createElementVNode("div", {
                          class: "label",
                          style: _normalizeStyle(`font-size: ${!item2.col && item2.label.length > 13 ? '11px' : 'inherit'}`)
                        }, _toDisplayString(item2.label), 5),
                        _createElementVNode("div", {
                          class: "value-list",
                          style: _normalizeStyle(`font-size:${!item2.col && item2.value3 ? '11px' : item2.value2 ? '13px' : '15px'}`)
                        }, [
                          _createElementVNode("div", {
                            class: "value",
                            style: _normalizeStyle(`font-size: ${!item2.col && item2.value.length > 20 ? '13px' : 'inherit'}`)
                          }, _toDisplayString(item2.value), 5),
                          (item2.value2)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(item2.value2), 1))
                            : _createCommentVNode("", true),
                          (item2.value3)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(item2.value3), 1))
                            : _createCommentVNode("", true)
                        ], 4)
                      ], 6))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.specialParamsData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specialParamsData, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "params-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_28, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_29, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrenList, (item2, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(index2 % 2 === 0 ?  'sub-item' : 'sub-item bg-gray'),
                        key: index2
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2, (value, key) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: key,
                            class: "value-item",
                            style: _normalizeStyle(`font-size: ${!item2.col && item2.label.length > 13 ? '11px' : 'inherit'}`)
                          }, _toDisplayString(value), 5))
                        }), 128))
                      ], 2))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (JSON.stringify(_ctx.otherData) !== '{}')
        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherData.descList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "desc-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_32, _toDisplayString(item.label), 1),
                  _createElementVNode("div", _hoisted_33, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.textObj, (item2, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "text",
                        key: index2
                      }, _toDisplayString(item2), 1))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.tabsPane.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsPane, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(_ctx.currentTabsIndex === index ? 'title-item active-item' : 'title-item'),
                  key: index,
                  onClick: ($event: any) => (_ctx.currentTabsIndex = index)
                }, _toDisplayString(item.label), 11, _hoisted_36))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_37, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsPane, (item, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass('content-item ' + item.className),
                  key: index
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.dataList, (item2, index2) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "detail-item",
                      key: index2
                    }, [
                      _createElementVNode("div", _hoisted_38, _toDisplayString(item2.title), 1),
                      _createElementVNode("div", _hoisted_39, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.textObj, (text) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "sub-item",
                            key: text
                          }, _toDisplayString(text), 1))
                        }), 128))
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_40, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.textObj2, (text) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "sub-item",
                            key: text
                          }, _toDisplayString(text), 1))
                        }), 128))
                      ], 512), [
                        [_vShow, item2.textObj2]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_41, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.textObj3, (text) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "sub-item",
                            key: text
                          }, _toDisplayString(text), 1))
                        }), 128))
                      ], 512), [
                        [_vShow, item2.textObj3]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_42, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.textObj4, (text) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "sub-item",
                            key: text
                          }, _toDisplayString(text), 1))
                        }), 128))
                      ], 512), [
                        [_vShow, item2.textObj4]
                      ])
                    ]))
                  }), 128))
                ], 2)), [
                  [_vShow, _ctx.currentTabsIndex === index]
                ])
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_CommonFooter)
  ]))
}